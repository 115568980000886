import axios from 'axios';
import { api } from 'config/config';
import { call, put } from 'redux-saga/effects';
import * as actions from '../actions';

/** function that returns an axios call */
function inquiryFormApi() {
    return axios.request({
        method: 'post',
        url: api.path('inquiry.formdata'),
        data: {
            client_url: window.location.host,
        },
    });
}

export function* fetchInquiryFormData(action) {
    const { data: response } = yield call(inquiryFormApi, api.path('inquiry.formdata'));
    yield put(actions.setInquiryFormData(response.data));
}

export default {};
