import * as actionTypes from '../mutation-types';

export const setLoading = (value) => {
    return {
        type: actionTypes.UI_SET_LOADING,
        value,
    };
};

export const snackbarSuccess = (message) => {
    return {
        type: actionTypes.UI_SNACKBAR_SUCCESS,
        message,
    };
};

export const snackbarError = (message) => {
    return {
        type: actionTypes.UI_SNACKBAR_ERROR,
        message,
    };
};

export const setCurrency = (currency) => {
    return {
        type: actionTypes.UI_SET_CURRENCY,
        currency,
    };
};

export const snackbarClear = () => {
    return {
        type: actionTypes.UI_SNACKBAR_CLEAR,
    };
};

export const setLanguages = (languages) => {
    return {
        type: actionTypes.UI_SET_LANGUAGES,
        languages,
    };
};

export const setPendingCoachRequests = (pendingCoachRequests) => {
    return {
        type: actionTypes.UI_SET_PENDING_COACH_REQUESTS,
        pendingCoachRequests,
    };
};

export const setCoachSkills = (coachSkills) => {
    return {
        type: actionTypes.UI_SET_COACH_SKILLS,
        coachSkills,
    };
};

export const setCoachLanguages = (coachLanguages) => {
    return {
        type: actionTypes.UI_SET_COACH_LANGUAGES,
        coachLanguages,
    };
};

export const setCoachSubjects = (coachSubjects) => {
    return {
        type: actionTypes.UI_SET_COACH_SUBJECTS,
        coachSubjects,
    };
};

export const setGrades = (grades) => {
    return {
        type: actionTypes.UI_SET_GRADES,
        grades,
    };
};

export const fetchManagerBaseData = () => {
    return {
        type: actionTypes.UI_FETCH_MANAGER_BASEDATA,
    };
};

export const setManagerBasedata = (data) => {
    return {
        type: actionTypes.UI_SET_MANAGER_BASEDATA,
        data,
    };
};
