import * as actionTypes from '../mutation-types';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        user,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error,
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return {
        type: actionTypes.AUTH_CHECK_TIMEOUT,
        expirationTime,
    };
};

export const login = (email, password) => {
    return {
        type: actionTypes.AUTH_USER,
        email,
        password,
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path,
    };
};

export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE,
    };
};

export const authChangePassword = (password, passwordConfirmation, oldPassword) => {
    return {
        type: actionTypes.AUTH_CHANGE_PASSWORD,
        password,
        passwordConfirmation,
        oldPassword,
    };
};

export const authPasswordChanged = (user) => {
    return {
        type: actionTypes.AUTH_PASSWORD_CHANGED,
        user,
    };
};

export const authSetUser = (user) => {
    return {
        type: actionTypes.AUTH_SET_USER,
        user,
    };
};

export const authSetLanguage = (language) => {
    return {
        type: actionTypes.AUTH_SET_LANGUAGE,
        language,
    };
};
