// import InquiryContainer from 'components/inquiry/InquiryContainer';
import Loader from 'components/ui/AiPageLoader';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import i18n from '../config/i18n';
import * as actions from '../store/actions/index';
// import BackendRoutes from './BackendRoutes';
import PublicRoute from './PublicRoute';

const basePath = '';
const defaultLocale = 'de';

const supportedLocale = (locale) => {
  return true;
};

const InquiryContainer = React.lazy(() => import('components/inquiry/InquiryContainer'));
const EvaluationAudience = React.lazy(() => import('components/evaluation/AudienceDetail'));

const BackendRoutes = React.lazy(() => import('./BackendRoutes'));

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PublicRoute path="/" component={InquiryContainer} exact />
        <PublicRoute noTitle path="/evaluations/:hash" component={EvaluationAudience} exact />
        <BackendRoutes />
      </Switch>
    </Suspense>
  );
};
/*
const RootRedirect = () => {
    let [locale] = (
        navigator.language ||
        navigator.browserLanguage ||
        defaultLocale
    ).split('-');
    if (!supportedLocale(locale)) locale = defaultLocale;
    return <Redirect to={`${basePath}/${locale}`} />;
}; */

const LangRedirect = ({ match, location }) => {
  let { locale } = match.params;

  if (!supportedLocale(locale)) locale = defaultLocale;
  i18n.changeLanguage(locale);

  const newUrl = location.pathname.replace('/' + locale, '');

  return <Redirect to={newUrl} />;
};

const AppRouter = ({ onTryAutoSignup }) => {
  useEffect(() => {
    onTryAutoSignup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${basePath}/:locale([a-zA-z]{2})`} component={LangRedirect} />
        <Route path={`${basePath}/`} component={Routes} />
      </Switch>
    </BrowserRouter>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

AppRouter.propTypes = {
  onTryAutoSignup: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AppRouter);
