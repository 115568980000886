/* eslint-disable guard-for-in */
// Make this global to use all over the application
const CONFIG = {};

CONFIG.apiUrl = process.env.REACT_APP_API_BASE_URL;

export default CONFIG;

// Helper function for URL handling
class URL {
  constructor(base) {
    this.base = base;
  }

  path(path, args) {
    const splittedPath = path.split('.');
    let obj = this;
    let url = this.base;

    for (let i = 0; i < splittedPath.length && obj; i += 1) {
      if (obj.url) {
        url += `/${obj.url}`;
      }

      obj = obj[splittedPath[i]];
    }
    if (obj) {
      url = `${url}/${typeof obj === 'string' ? obj : obj.url}`;
    }

    if (args) {
      // eslint-disable-next-line guard-for-in
      // eslint-disable-next-line no-restricted-syntax
      for (const key in args) {
        url = url.replace(`:${key}`, args[key]);
      }
    }

    return url;
  }
}

// API Route mapping
export const api = Object.assign(new URL(CONFIG.apiUrl), {
  url: '',
  inquiry: {
    url: 'inquiries',
    formdata: 'formdata',
    managerFormdata: 'formdata/manager',
  },
  coaches: {
    url: 'coaches',
    requestValidation: 'request-validation',
    approve: ':id/approve',
    unavailabilities: ':id/unavailabilities',
    updataUnavailabilities: ':userId/unavailabilities/:id',
    deleteUnavailabilities: ':userId/unavailabilities/:id',
    requests: 'requests/:id',
    // multipleRequests: 'requests', not used?
    list: '',
    activeList: 'active',
  },
  auth: {
    url: '',
    signin: 'login',
    signup: 'signup',
    passwordCreate: 'password/create',
    passwordTokenFind: 'password/find/:token',
    passwordReset: 'password/reset',
  },
  workshops: {
    url: 'workshops',
    detail: ':id',
    upcoming: 'upcoming',
    formdata: 'formdata',
    series: ':id/series',
    formCoachRequests: 'form-coach-requests',
    coachRequests: ':id/coach-requests',
    coachRequestDetail: 'coach-requests/:id',
    allocations: 'allocations',
    formAllocations: ':id/form-allocations',
    setState: ':id/set-state',
    assignCoach: ':id/assign-coach',
    dismissCoach: ':id/dismiss-coach',
    invoices: 'invoices',
    setInvoiced: ':id/set-invoiced',
    replicate: 'replicate',
  },
  inquiries: {
    url: 'inquiries',
    get: ':id',
    update: ':id',
    assign: ':id/assign',
    validate: ':id/validate',
  },
  users: {
    url: 'users',
    settings: 'settings',
    updateSettings: ':id/settings',
    changePassword: 'password',
    setLanguage: 'settings/language',
  },
  profiles: {
    url: 'profiles',
    detail: ':id',
  },
  basedata: {
    url: 'basedata',
    manager: 'manager',
  },
  orders: {
    url: 'orders',
    material: 'material',
  },
  masterData: {
    url: 'master-data',
    workshops: 'workshops',
    updateWorkshop: 'workshops/:id',
    deleteWorkshop: 'workshops/:id',
    workshopFilesDelete: 'workshops/:id/files',
    materials: 'materials',
    updateMaterial: 'materials/:id',
    deleteMaterial: 'materials/:id',
    associates: 'associates',
    deleteAssociate: 'associates/:id',
    updateAssociate: 'associates/:id',
  },
  news: {
    url: 'news',
    get: ':language',
    update: ':id',
    delete: ':id',
  },
  stats: {
    url: 'statistics',
    formData: 'form-data',
    get: '',
    export: 'export',
    coach: 'trainer/:id',
  },
  expenses: {
    url: 'expenses',
    coachList: 'coach/:id',
    financeList: 'finance',
    financeDetail: ':id',
    delete: ':id',
  },
  coachFees: {
    url: 'coach-fees',
    financeList: 'finance',
    extra: 'extra-fees',
    setPaidOut: ':id/set-paid-out',
    deleteExtraFee: 'extra-fees/:id',
  },
  evaluation: {
    url: 'evaluations',
    detail: ':id',
    update: ':id',
    byCoach: 'by-coach',
    byAudience: 'by-audience',
    setValidated: ':id/set-validated',
    listMy: '',
  },
  dashboard: {
    url: 'dashboard',
    get: ':language',
  },
  logout: 'logout',
  me: 'user',
});
