import { all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../mutation-types';
import {
    authChangePassword,
    authCheckStateSaga,
    authSetLanguage,
    authSetUserSaga,
    authUserSaga,
    checkAuthTimeoutSaga,
    logoutSaga,
} from './auth';
import { fetchInquiryFormData } from './inquiry';
import { fetchManagerBasedata } from './ui';

function* watchAuth() {
    yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
    yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
    yield takeEvery(actionTypes.AUTH_SET_USER, authSetUserSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
    yield takeEvery(actionTypes.AUTH_CHANGE_PASSWORD, authChangePassword);
    yield takeEvery(actionTypes.AUTH_SET_LANGUAGE, authSetLanguage);
    yield takeEvery(actionTypes.AUTH_LOGOUT, logoutSaga);
}

function* watchInquiry() {
    yield takeEvery(actionTypes.INQUIRY_LOAD_FORMDATA, fetchInquiryFormData);
}

function* watchUi() {
    yield takeEvery(actionTypes.UI_FETCH_MANAGER_BASEDATA, fetchManagerBasedata);
}

export default function* rootSaga() {
    yield all([watchAuth(), watchInquiry(), watchUi()]);
}
