import { updateObject } from 'helpers/utility';
import * as actionTypes from '../mutation-types';

const initialState = {
    token: null,
    userId: null,
    role: null,
    error: null,
    loading: false,
    user: null,
    authRedirectPath: '/',
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.user.id,
        role: action.user.role,
        user: action.user,
        error: null,
        loading: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const authPasswordChanged = (state, action) => {
    return updateObject(state, {
        user: action.user,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, initialState);
};

const authUser = (state, action) => {
    return updateObject(state, {
        userId: action.user.id,
        role: action.user.role,
        user: action.user,
        error: null,
        loading: false,
    });
};

const authSetLanguage = (state, action) => {
    const { user } = state;
    user.language = action.language;
    return updateObject(state, {
        user,
    });
};

/* later 
const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};
*/

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_SET_USER:
            return authUser(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_PASSWORD_CHANGED:
            return authPasswordChanged(state, action);
        case actionTypes.AUTH_SET_LANGUAGE:
            return authSetLanguage(state, action);
        default:
            return state;
    }
};

export default reducer;
