import axios from 'axios';
import { api } from 'config/config';
import i18n from 'config/i18n';
import { call, delay, put } from 'redux-saga/effects';
import * as actions from '../actions/index';

const defaultLocale = 'de';

const supportedLocale = (locale) => {
    // TODO:
    return true;
};

/** function that returns an axios call */
function loginApi(email, password) {
    return axios.request({
        method: 'post',
        url: api.path('auth.signin'),
        data: {
            email,
            password,
        },
    });
}

function changePasswordApi(password, passwordConfirmation, oldPassword) {
    return axios.request({
        method: 'put',
        url: api.path('users.changePassword'),
        data: {
            password,
            password_confirmation: passwordConfirmation,
            old_password: oldPassword,
        },
    });
}

function setLanguageApi(language) {
    return axios.request({
        method: 'put',
        url: api.path('users.setLanguage'),
        data: {
            language,
        },
    });
}

export function* logoutSaga(action) {
    yield action; // TODO
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('expiration_date');
    yield localStorage.removeItem('user');
}

export function* checkAuthTimeoutSaga(action) {
    yield delay(action.expirationTime * 1000);
    yield put(actions.logout());
}

export function* authUserSaga(action) {
    yield put(actions.authStart());
    try {
        const { data: response } = yield call(loginApi, action.email, action.password);
        const expirationDate = new Date(response.data.expires_at * 1000);
        yield localStorage.setItem('token', response.data.token);
        yield localStorage.setItem('expiration_date', expirationDate);
        yield localStorage.setItem('user', JSON.stringify(response.data.user));
        i18n.changeLanguage(response.data.user.language);
        yield put(actions.authSuccess(response.data.token, response.data.user));
        yield put(actions.checkAuthTimeout(response.data.expires_at * 1000 - new Date().getTime()));
        yield put(actions.snackbarSuccess(i18n.t('Login successful')));
    } catch (error) {
        yield put(actions.authFail(error.response.data));
        yield put(actions.snackbarError(i18n.t(`auth.${error.response.data.error}`)));
    }
}

export function* authSetUserSaga(action) {
    yield localStorage.setItem('user', JSON.stringify(action.user));
}

export function* authChangePassword(action) {
    try {
        const { data: response } = yield call(
            changePasswordApi,
            action.password,
            action.passwordConfirmation,
            action.oldPassword
        );
        yield localStorage.setItem('user', JSON.stringify(response.data.user));
        yield put(actions.authPasswordChanged(response.data.user));
        yield put(actions.snackbarSuccess(i18n.t('Passwort changed successfully')));
    } catch (error) {
        yield put(actions.snackbarError(i18n.t(`${error.response.data.message}`)));
    }
}

export function* authSetLanguage(action) {
    yield put(actions.setLoading(true));
    try {
        let locale = action.language;
        yield call(setLanguageApi, locale);
        if (!supportedLocale(locale)) locale = defaultLocale;
        i18n.changeLanguage(locale);
        const user = JSON.parse(localStorage.getItem('user'));
        user.language = locale;
        yield localStorage.setItem('user', JSON.stringify(user));
        yield put(actions.snackbarSuccess(i18n.t('Language changed')));
    } catch (error) {
        yield put(actions.snackbarError(i18n.t(`${error.response.data.message}`)));
    }
    yield put(actions.setLoading(false));
}

export function* authCheckStateSaga(action) {
    yield action; // TODO
    const token = yield localStorage.getItem('token');
    if (!token) {
        yield put(actions.logout());
    } else {
        const expirationDate = yield new Date(localStorage.getItem('expiration_date'));
        if (expirationDate <= new Date()) {
            yield put(actions.logout());
        } else {
            const user = yield JSON.parse(localStorage.getItem('user'));
            yield put(actions.authSuccess(token, user));
            yield put(
                actions.checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000)
            );
        }
    }
}

export default {};
