import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PREFIX = 'AiHeader';
const classes = {
    appBar: `${PREFIX}-appBar`,
    toolbar: `${PREFIX}-toolbar`,
    divider: `${PREFIX}-divider`,
    toolbarTitle: `${PREFIX}-toolbarTitle`,
};
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.appBar}`]: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    [`& .${classes.toolbar}`]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [`& .${classes.divider}`]: {
        borderColor: '#fff',
        height: '37px',
        marginLeft: '20px',
        marginRight: '20px',
    },
    [`& .${classes.toolbarTitle}`]: {
        marginLeft: '5px',
    },
}));

export default function ({ noTitle }) {
    const { t } = useTranslation();
    return (
        <Root>
            <AppBar position="absolute" className={classes.appBar}>
                <Container
                    maxWidth="xl"
                    sx={{
                        width: 1280,
                    }}
                >
                    <Toolbar className={classes.toolbar} variant="dense" sx={{ p: { sm: 0 } }}>
                        <Button size="small" component="a" href="/">
                            <Logo
                                className={classes.logo}
                                style={{ height: '37px', fill: '#ffffff' }}
                            />
                        </Button>
                        <Divider orientation="vertical" className={classes.divider} />
                        {noTitle === true ? (
                            <Typography
                                component="span"
                                color="inherit"
                                variant="inherit"
                                noWrap
                                className={`${classes.toolbarTitle} sitetitle`}
                            >
                                Workshops
                            </Typography>
                        ) : (
                            <Typography
                                component="span"
                                color="inherit"
                                variant="inherit"
                                noWrap
                                className={`${classes.toolbarTitle} sitetitle`}
                            >
                                {t('Public layout title')}
                            </Typography>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </Root>
    );
}
