import axios from 'axios';
import configureStore from '../store/config/configureStore';

axios.interceptors.request.use(
    (config) => {
        // Add some headers
        config.headers.Accept = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        // config.headers['X-Requested-With'] = 'XMLHttpRequest';
        // config.headers['Access-Control-Allow-Origin'] = '*';

        // Default (internal) request - use the middleware api auth token
        // Add the token - only for middleware api request
        const store = configureStore();
        const state = store.getState();

        if (state.auth.token) {
            config.headers.Authorization = `Bearer ${state.auth.token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.defaults.trailingSlash = true;

// remove trailing slash on url
axios.interceptors.request.use((config) => {
    if (config.url[config.url.length - 1] === '/') {
        config.url = config.url.slice(0, -1);
    }
    return config;
});
