import axios from 'axios';
import { api } from 'config/config';
import { call, put } from 'redux-saga/effects';
import * as actions from '../actions';

/** function that returns an axios call */
function fetchManagerBasedataApi() {
    return axios.request({
        method: 'get',
        url: api.path('basedata.manager'),
    });
}

export function* fetchManagerBasedata(action) {
    const { data: response } = yield call(fetchManagerBasedataApi, api.path('inquiry.formdata'));
    yield put(actions.setLanguages(response.languages));
    yield put(actions.setCurrency(response.currency));
    yield put(actions.setPendingCoachRequests(response.pending_coach_requests));
    yield put(actions.setCoachSkills(response.coach_skills));
    yield put(actions.setCoachLanguages(response.coach_languages));
    yield put(actions.setCoachSubjects(response.coach_subjects));
    yield put(actions.setGrades(response.grades));
}

export default {};
