import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import authReducer from '../reducers/auth';
import inquiryReducer from '../reducers/inquiry';
import uiReducer from '../reducers/ui';
import rootSaga from '../sagas';

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    combineReducers({
        auth: authReducer,
        inquiry: inquiryReducer,
        ui: uiReducer,
    }),
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default () => {
    return store;
};
