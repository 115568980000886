import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';
import './Footer.css';

export default function (props) {
    return (
        <footer>
            <section>
                <Container sx={{ maxWidth: 1280 }}>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <ul className="link-list">
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/kontakt/medien"
                                        title="Medien"
                                        target="_new"
                                    >
                                        Medien
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/menschenrechtsbildung"
                                        title="Bildung"
                                        target="_new"
                                    >
                                        Bildung
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/kontakt/jobs"
                                        title="Jobs"
                                        target="_new"
                                    >
                                        Jobs
                                    </a>
                                </li>
                                <li>
                                    <a href="/admin" title="Youth">
                                        Youth
                                    </a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul className="link-list last">
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/kontakt"
                                        title="Kontakt"
                                        target="_new"
                                    >
                                        Kontakt
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/kontakt/impressum-etc"
                                        title="Impressum"
                                        target="_new"
                                    >
                                        Impressum
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.amnesty.ch/de/kontakt/impressum-etc/rechtliche-hinweise/datenschutz"
                                        title="Datenschutz"
                                        target="_new"
                                    >
                                        Datenschutz
                                    </a>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section>
                <Container>
                    <Grid container spacing={1}>
                        <Grid item xs={4} className="left address">
                            <span>Amnesty International . Schweizer Sektion</span>

                            <span>Speichergasse 33 . CH-3001 Bern</span>

                            <span>Tel +41 31 307 22 22 . Fax +41 31 307 22 33</span>

                            <span>
                                <a href="https://www.amnesty.ch/de/kontakt/adressen/spendenkonto">
                                    IBAN CH52 0900 0000 3000 3417 8
                                </a>
                            </span>
                        </Grid>
                        <Grid item xs={2} className="location left">
                            <a
                                href="https://www.google.ch/maps/place/Amnesty+International+-+Schweizer+Sektion/@46.9502312,7.4425787,17z/data=!3m1!4b1!4m2!3m1!1s0x478e39bf7dc55f69:0x56266da5b4a5d733"
                                id="footer-location"
                                target="_new"
                            >
                                Standort anzeigen
                            </a>
                        </Grid>
                        <Grid item xs={6} className="logo last">
                            &nbsp;
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section>
                <Container>
                    <Grid container spacing={1}>
                        © Amnesty International . Schweizer Sektion
                    </Grid>
                </Container>
            </section>
        </footer>
    );
}
