import * as actionTypes from '../mutation-types';

export const loadInquiryFormData = () => {
    return {
        type: actionTypes.INQUIRY_LOAD_FORMDATA,
    };
};

export const setInquiryFormData = (data) => {
    return {
        type: actionTypes.INQUIRY_SET_FORMDATA,
        data,
    };
};

export const selectWorkshop = (data) => {
    return {
        type: actionTypes.SELECT_WORKSHOP,
        data,
    };
};

export const workshopDataChanged = (data) => {
    return {
        type: actionTypes.WORKSHOP_DATA_CHANGED,
        data,
    };
};

export const contactDataChanged = (data) => {
    return {
        type: actionTypes.INQUIRY_CONTACT_DATA_CHANGED,
        data,
    };
};

export const knowledgeDataChanged = (data) => {
    return {
        type: actionTypes.INQUIRY_KNOWLEDGE_DATA_CHANGED,
        data,
    };
};
