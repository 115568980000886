import { updateObject } from '../../helpers/utility';
import * as actionTypes from '../mutation-types';

const initialState = {
    loading: false,
    successSnackbarOpen: false,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
    message: '',
    pendingCoachRequests: null,
    coachSkills: [],
    coachSubjects: [],
    coachLanguages: [],
    languages: [],
    grades: [],
    currency: 'CHF',
    basedata: {},
};

const setLoading = (state, action) => {
    return updateObject(state, {
        loading: action.value,
    });
};

const snackbarSuccess = (state, action) => {
    return updateObject(state, {
        successSnackbarOpen: true,
        message: action.message,
    });
};

const snackbarError = (state, action) => {
    return updateObject(state, {
        errorSnackbarOpen: true,
        message: action.message,
    });
};

const snackbarClear = (state, action) => {
    const updated = state;
    updated.successSnackbarOpen = false;
    updated.errorSnackbarOpen = false;
    updated.infoSnackbarOpen = false;
    return updateObject(state, updated);
};

const setLanguages = (state, action) => {
    return updateObject(state, {
        languages: action.languages,
    });
};

const setCurrency = (state, action) => {
    return updateObject(state, {
        currency: action.currency,
    });
};

const setManagerBasedata = (state, action) => {
    return updateObject(state, {
        basedata: action.data,
    });
};

const setPendingCoachRequests = (state, action) => {
    return updateObject(state, {
        pendingCoachRequests: action.pendingCoachRequests,
    });
};

const setCoachSkills = (state, action) => {
    return updateObject(state, {
        coachSkills: action.coachSkills,
    });
};

const setCoachLanguages = (state, action) => {
    return updateObject(state, {
        coachLanguages: action.coachLanguages,
    });
};

const setGrades = (state, action) => {
    return updateObject(state, {
        grades: action.grades,
    });
};

const setCoachSubjects = (state, action) => {
    return updateObject(state, {
        coachSubjects: action.coachSubjects,
    });
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UI_SET_LOADING:
            return setLoading(state, action);
        case actionTypes.UI_SNACKBAR_SUCCESS:
            return snackbarSuccess(state, action);
        case actionTypes.UI_SNACKBAR_ERROR:
            return snackbarError(state, action);
        case actionTypes.UI_SNACKBAR_CLEAR:
            return snackbarClear(state, action);
        case actionTypes.UI_SET_LANGUAGES:
            return setLanguages(state, action);
        case actionTypes.UI_SET_CURRENCY:
            return setCurrency(state, action);
        case actionTypes.UI_SET_MANAGER_BASEDATA:
            return setManagerBasedata(state, action);
        case actionTypes.UI_SET_PENDING_COACH_REQUESTS:
            return setPendingCoachRequests(state, action);
        case actionTypes.UI_SET_COACH_SKILLS:
            return setCoachSkills(state, action);
        case actionTypes.UI_SET_COACH_LANGUAGES:
            return setCoachLanguages(state, action);
        case actionTypes.UI_SET_COACH_SUBJECTS:
            return setCoachSubjects(state, action);
        case actionTypes.UI_SET_GRADES:
            return setGrades(state, action);
        default:
            return state;
    }
};

export default reducer;
