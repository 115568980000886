import { v4 as uuidv4 } from 'uuid';
import { updateObject } from '../../helpers/utility';
import * as actionTypes from '../mutation-types';

const initialState = {
    formdata: {
        offeredWorkshops: [],
        grades: [],
        infrastructure: [],
    },
    workshops: [],
    contact: {
        gender: '2',
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        institute: '',
        streetAddress: '',
        addressAddition: '',
        postcode: '',
        location: '',
        institutePhone: '',
        useInvoiceAddress: 'false',
        invoiceInstitute: '',
        invoiceFirstname: '',
        invoiceLastname: '',
        invoiceStreetAddress: '',
        invoiceAddressAddition: '',
        invoicePostcode: '',
        invoiceLocation: '',
    },
    knowledge: {
        grade: '',
        class: '',
        numberParticipantsTotal: '',
        numberParticipantsM: '',
        numberParticipantsF: '',
        numberParticipantsN: '',
        preknowledge: '',
        infrastructure: [],
    },
};

const setInquiryFormData = (state, action) => {
    return updateObject(state, {
        formdata: {
            offeredWorkshops: action.data.workshops,
            grades: action.data.grades,
            infrastructure: action.data.infrastructure,
        },
    });
};

const getNewWorkshopObj = (id) => ({
    uid: uuidv4(),
    workshopId: id,
    date: null,
    startTime: '08:00',
    endTime: '10:00',
    altDate: null,
    altStartTime: new Date(),
    altEndTime: '10:00',
});

const selectWorkshop = (state, action) => {
    let workshops = [...state.workshops];
    if (workshops.some((el) => el.workshopId === action.data.id)) {
        const foundItems = workshops.filter((i) => i.workshopId === action.data.id);
        const rest = workshops.filter((i) => i.workshopId !== action.data.id);
        const cFound = foundItems.length;
        if (cFound < action.data.amount) {
            for (let i = 0; i < action.data.amount - cFound; i += 1) {
                foundItems.push(getNewWorkshopObj(action.data.id));
            }
        } else {
            const len = Math.abs(cFound - action.data.amount);
            foundItems.splice(len * -1, len);
        }
        workshops = [...rest, ...foundItems];
    } else {
        for (let i = 0; i < action.data.amount; i += 1) {
            workshops.push(getNewWorkshopObj(action.data.id));
        }
    }
    return updateObject(state, { workshops });
};

const workshopDataChanged = (state, action) => {
    const workshops = [...state.workshops];
    const workshopIndex = workshops.findIndex((i) => i.uid === action.data.uid);
    workshops[workshopIndex][action.data.field] = action.data.value;
    return updateObject(state, { workshops });
};

const contactDataChanged = (state, action) => {
    const contact = { ...state.contact };
    contact[action.data.field] = action.data.value;
    return updateObject(state, { contact });
};

const knowledgeDataChanged = (state, action) => {
    const knowledge = { ...state.knowledge };
    if (action.data.field === 'infrastructure') {
        if (action.data.checked) {
            const foundInd = knowledge[action.data.field].findIndex(
                (item) => item === action.data.value
            );
            if (foundInd === -1) {
                knowledge[action.data.field].push(action.data.value);
            }
        } else {
            const foundInd = knowledge[action.data.field].findIndex(
                (item) => item === action.data.value
            );
            knowledge[action.data.field].splice(foundInd, 1);
        }
    } else {
        knowledge[action.data.field] = action.data.value;
    }

    return updateObject(state, { knowledge });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INQUIRY_SET_FORMDATA:
            return setInquiryFormData(state, action);
        case actionTypes.SELECT_WORKSHOP:
            return selectWorkshop(state, action);
        case actionTypes.WORKSHOP_DATA_CHANGED:
            return workshopDataChanged(state, action);
        case actionTypes.INQUIRY_CONTACT_DATA_CHANGED:
            return contactDataChanged(state, action);
        case actionTypes.INQUIRY_KNOWLEDGE_DATA_CHANGED:
            return knowledgeDataChanged(state, action);
        default:
            return state;
    }
};

export default reducer;
