import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AiPageLoader() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Logo
          sx={{
            color: '#000000',
            marginBottom: 2,
            width: 120,
          }}
        />
        <br />

        <Typography
          sx={{ color: (theme) => theme.palette.primary.light }}
          variant="body2"
          component="span"
        >
          {t('Loading Application ...')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AiPageLoader;
