import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translationsDe from '../locales/de.json';
import translationsFr from '../locales/fr.json';
import translationsIt from '../locales/it.json';

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        debug: true,
        fallbackLng: 'en', // use en if detected lng is not available
        lng: localStorage.getItem('i18nextLng') || 'de',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        useLocalStorage: true,
        react: {
            useSuspense: false,
        },

        lngWhitelist: ['de', 'fr', 'it'],

        detector: {
            order: [
                'querystring',
                'cookie',
                'localstorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],
        },

        resources: {
            de: {
                translations: translationsDe,
            },
            fr: {
                translations: translationsFr,
            },
            it: {
                translations: translationsIt,
            },
        },
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
    });

export default i18n;
