import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const defaultTheme = {
    spacing: 8,
    palette: {
        common: {
            black: 'rgba(54, 44, 44, 1)',
            white: '#fff',
        },
        background: {
            paper: '#fff',
            paperDark: 'rgba(170, 157, 145, 0.3)',
            default: 'rgba(246, 242, 240, 1)',
        },
        primary: {
            light: 'rgba(170, 157, 145, 1)',
            main: 'rgba(54, 44, 44, 1)',
            dark: 'rgba(80, 71, 65, 1)',
            contrastText: '#fff',
        },
        secondary: {
            light: 'rgba(249, 235, 62, 1)',
            main: 'rgba(255, 240, 0, 1)',
            dark: 'rgba(255, 218, 0, 1)',
            contrastText: 'rgba(54, 44, 44, 1)',
        },
        error: {
            light: 'rgba(228, 69, 99, 1)',
            main: 'rgba(185, 35, 70, 1)',
            dark: 'rgba(165, 29, 67, 1)',
            contrastText: '#fff',
        },
        success: {
            main: '#298a71',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(54, 44, 44, 1)',
            secondary: 'rgba(80, 71, 65, 1)',
            disabled: 'rgba(202, 194, 185, 1)',
            hint: 'rgba(120, 106, 96, 1)',
        },
        white: '#fff',
        ai: {
            yellowDark: '#ffc800',
            yellow: 'rgba(255, 240, 0, 1)',
            red: '#b92346',
            orange: '#dc8c11',
            purple: '#963c6e',
            teal: '#298a71',
            blue: '#32aac8',
            powderblue: '#5d7883',
            olive: '#96943f',
            grey1: '#504741',
            grey2: '#786A60',
            grey3: '#aa9d91',
            grey4: '#cac2b9',
            grey5: '#e4dfd8',
        },
    },
    typography: {
        fontSize: 13,
        fontFamily: [
            '"Trade Gothic Next W01"',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '-apple-system',
            'BlinkMacSystemFont',
        ].join(','),
        h1: {
            fontFamily: '"TradeGothicW01-BoldCn20 675334"',
            textTransform: 'uppercase',
            fontSize: 24,
            lineHeight: 1,
            padding: '1em 0px 0.8em 0px',
        },
        h2: {
            fontFamily: '"TradeGothicW01-BoldCn20 675334"',
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: '"TradeGothicW01-BoldCn20 675334"',
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: '"TradeGothicW01-BoldCn20 675334"',
            textTransform: 'uppercase',
        },
        h5: {
            fontFamily: '"TradeGothicNextW01-HvCn 707049"',
            textTransform: 'uppercase',
        },
        h6: {
            fontFamily: '"TradeGothicNextW01-HvCn 707049"',
            textTransform: 'uppercase',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1600,
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.MuiContainer-maxWidthLg': {
                        maxWidth: 1280,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: 'rgba(54, 44, 44, 1) !important',
                    },
                },
                colorSecondary: {
                    color: 'rgba(54, 44, 44, 1) !important',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    '&.Mui-active': {
                        width: '1.1em',
                        height: '1.1em',
                        fill: '#FFf000',
                        border: '2px solid #FFf000',
                        borderRadius: 20,
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontWeight: 'bold',
                        },
                    },
                    '&.Mui-completed': {
                        fill: '#298A71',
                    },
                },
                text: {
                    fill: '#fff',
                },
            },
        },
    },
};

if (window.location.hostname.includes('watlab')) {
    defaultTheme.palette.primary.main = '#000';
}

const theme = createTheme(defaultTheme);

const themeWithFonts = responsiveFontSizes(theme);

export default themeWithFonts;
