import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { connect } from 'react-redux';
import { snackbarClear } from 'store/actions';

function AiSnackbar({
    snackbarClear,
    message,
    successSnackbarOpen,
    errorSnackbarOpen,
    infoSnackbarOpen,
}) {
    let severity = 'success';
    if (errorSnackbarOpen === true) {
        severity = 'error';
    }
    if (infoSnackbarOpen === true) {
        severity = 'info';
    }
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={successSnackbarOpen || errorSnackbarOpen || infoSnackbarOpen}
            autoHideDuration={4000}
            onClose={() => snackbarClear()}
            aria-describedby="client-snackbar"
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => snackbarClear()}
                >
                    <CloseIcon>close</CloseIcon>
                </IconButton>,
            ]}
        >
            <MuiAlert severity={severity} onClose={snackbarClear}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

const mapStateToProps = (state) => ({
    message: state.ui.message,
    successSnackbarOpen: state.ui.successSnackbarOpen,
    errorSnackbarOpen: state.ui.errorSnackbarOpen,
    infoSnackbarOpen: state.ui.infoSnackbarOpen,
});

const mapDispatchToProps = (dispatch) => {
    return {
        snackbarClear: () => {
            dispatch(snackbarClear());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AiSnackbar);
