export const AUTH_START = 'AUTH_START';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_PASSWORD_CHANGED = 'AUTH_PASSWORD_CHANGED';
export const AUTH_SET_LANGUAGE = 'AUTH_SET_LANGUAGE';

export const INQUIRY_LOAD_FORMDATA = 'INQUIRY_LOAD_FORMDATA';
export const INQUIRY_SET_FORMDATA = 'INQUIRY_SET_FORMDATA';
export const SELECT_WORKSHOP = 'SELECT_WORKSHOP';
export const WORKSHOP_DATA_CHANGED = 'WORKSHOP_DATA_CHANGED';
export const INQUIRY_CONTACT_DATA_CHANGED = 'INQUIRY_CONTACT_DATA_CHANGED';
export const INQUIRY_KNOWLEDGE_DATA_CHANGED = 'INQUIRY_KNOWLEDGE_DATA_CHANGED';

export const UI_SNACKBAR_SUCCESS = 'UI_SNACKBAR_SUCCESS';
export const UI_SNACKBAR_ERROR = 'UI_SNACKBAR_ERROR';
export const UI_SNACKBAR_CLEAR = 'UI_SNACKBAR_CLEAR';
export const UI_SET_LANGUAGES = 'UI_SET_LANGUAGES';
export const UI_SET_PENDING_COACH_REQUESTS = 'UI_SET_PENDING_COACH_REQUESTS';
export const UI_SET_COACH_LANGUAGES = 'UI_SET_COACH_LANGUAGES';
export const UI_SET_COACH_SKILLS = 'UI_SET_COACH_SKILLS';
export const UI_SET_COACH_SUBJECTS = 'UI_SET_COACH_SUBJECTS';
export const UI_FETCH_MANAGER_BASEDATA = 'UI_FETCH_MANAGER_BASEDATA';
export const UI_SET_MANAGER_BASEDATA = 'UI_SET_MANAGER_BASEDATA';
export const UI_SET_LOADING = 'UI_SET_LOADING';
export const UI_SET_CURRENCY = 'UI_SET_CURRENCY';
export const UI_SET_GRADES = 'UI_SET_GRADES';
