import CssBaseline from '@mui/material/CssBaseline';
import Footer from 'components/layouts/public/Footer';
import Header from 'components/layouts/public/Header';
import AiSnackbar from 'components/ui/AiSnackbar';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

function PublicLayout({ noTitle, children }) {
    return (
        <>
            <CssBaseline />
            <AiSnackbar />
            <Header noTitle={noTitle === true} />
            {children}
            <Footer />
        </>
    );
}

PublicLayout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default connect()(PublicLayout);
